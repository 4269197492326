.homepage_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .mainpage_wrapper {
    width: 100%;
    text-align: center;
    .title {
      font-size: 45px;
      color: #707070;
    }
    .cards_wrapper {
      margin-top: 5%;
      display: flex;
      justify-content: center;
      color: #b0b0b0;
      a {
        text-decoration: none;
        color: #b0b0b0;
        &:hover {
          text-decoration: none;
          color: #276fbf;
        }
      }
      .single_card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 200px;
        border: 3px solid #b8b8b8;
        span {
          font-size: 30px;
          text-align: center;
          margin-top: 8%;
        }
        &:hover {
          border: 4px solid #276fbf;
        }
      }
    }
  }
}
