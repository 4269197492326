// mixins
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container {
  padding-top: 20px;
  .footer__container {
    margin-top: 1%;
    .add_row_wrapper {
      display: flex;
      flex-direction: column;
      font-size: 17px;
      color: #276fbf;
      font-weight: 500;
      .add_row_text {
        cursor: pointer;
        width: 7%;
      }
      .button__add__row {
        padding-bottom: 10px;
        color: #276fbf;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .error {
      color: red;
    }
    .button__footer__generate {
      margin-top: 3%;
      .generate_button {
        padding: 10px 20px;
        background-color: white;
        color: black;
        border: 2px solid black;
        margin-bottom: 6%;
      }
      .loader {
        border: 12px solid #f3f3f3; /* Light grey */
        border-top: 12px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 12px;
        height: 12px;
        animation: spin 2s linear infinite;
      }
    }
  }

  .button__footer__preview {
    padding-top: 20px;

    button {
      padding: 10px 20px;
      background-color: white;
      color: black;
      border: 2px solid black;
      margin-bottom: 6%;
    }
  }
}
