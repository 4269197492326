.dealer-info {
  display: flex;
  flex-direction: column;
  select {
    width: 30%;
    height: 50px;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    padding: 12px 15px;
    border: 1px solid #a9a9a9;
  }
}
