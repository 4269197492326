.sign_in_client_container {
  background-color: #e0e0e0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2% 10%;
  .white_bg_container {
    background-color: #fff;
    padding: 2% 0;
    .ca_logo_container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2%;
      .image_wrapper {
        width: 55%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .form_container {
      padding: 2% 15%;
    }
    .error_msg {
      text-align: center;
      color: #ff0000;
    }
    .first_time_msg {
      text-align: center;
      a {
        text-decoration: underline;
        color: #0000ff;
      }
    }
  }
}
