@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container {
  p,
  h2 {
    text-align: center;
  }
  width: 100%;
  .container__table {
    margin: 3%;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
    padding: 25px 25px;
    padding-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #276fbf;

    table {
      width: 90%;
      background-color: white;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .html_content_wrapper {
    .copy_wrapper {
      display: flex;
      justify-content: flex-end;
      span {
        margin-left: 1%;
        color: #276fbf;
        font-size: 15px;
        font-weight: 500;
      }
    }
    h2 {
      text-align: center;
    }
    .html__formatted__code {
      white-space: pre-wrap;
      background-color: white;
      color: black;
      padding: 15px;
      margin-bottom: 20px;
      border: 5px solid #276fbf;
    }
  }
  .container__footer {
    .button__send_email {
      button {
        padding: 10px 20px;
        background-color: white;
        color: black;
        border: 2px solid black;
        margin-bottom: 1%;
      }
      form {
        input {
          width: 40%;
          margin-top: 15px;
          margin-bottom: 15px;
        }
        button {
          padding: 10px 20px;
          background-color: white;
          color: black;
          border: 2px solid black;
        }
      }
      .loader {
        border: 12px solid #f3f3f3; /* Light grey */
        border-top: 12px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 12px;
        height: 12px;
        animation: spin 2s linear infinite;
      }
      span {
        font-size: 25px;
        color: #4b8f8c;
        font-weight: bold;
        padding-bottom: 10%;
      }
    }
  }
}
.preview-page {
  .header {
    color: #fff;
    text-align: center;
    font-size: 22px;
  }
}
