.row__wrapper {
  border: 2px dashed #276fbf;
  padding: 2%;
  display: flex;
  justify-content: space-between;
  margin: 2% 0px;

  .button__delete__row {
    width: 3%;

    .button__delete__icon {
      text-align: center;
      color: #276fbf;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .img__wrapper {
    overflow: hidden;
    max-width: 600px;
    padding-bottom: 10px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .img__info {
    width: 500px;
    padding-left: 10px;
    padding-bottom: 10px;
    input::placeholder {
      font-style: italic;
    }
  }

  .button__sort {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    .button__sort__icon {
      color: #276fbf;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
