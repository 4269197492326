.sftp_home_container {
  padding: 5% 0;
  .icon {
    text-align: center;
  }
  .sftp_info {
    padding: 2% 6%;
  }
  .form_container {
    width: 60%;
    padding-left: 4%;
    .error_msg {
      color: #ff0000;
    }
  }
}
