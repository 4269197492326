.eblast_page_container {
  .header {
    color: #fff;
    text-align: center;
    font-size: 22px;
  }
  .create_btn {
    padding: 1% 7%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .filter_section {
    display: flex;
    padding: 2%;
    background-color: #fff;
    .text {
      font-size: 18px;
      margin-right: 10px;
    }
  }
}
span.anticon.anticon-right,
span.anticon.anticon-left {
  display: flex;
}
