.user_profile_container {
  background-color: #e0e0e0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2% 10%;
  .profile_white_background {
    background-color: #fff;
    width: 100%;
    padding: 2% 7%;
  }
}
